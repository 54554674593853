/*-- Website Css --*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
html, body {
    scroll-behavior: smooth;
}
html::-webkit-scrollbar {
    width: 10px;
    background-color: #ebebeb;
}
html::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ed1b24;
}
body {
    font-family: 'Poppins', sans-serif;
}
body .container {
    max-width: 1320px;
}
img {
    width: 100%;
}
h4.small-hdn {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ED1B24;
    margin: 0  0 15px;
}
h2.sec-hdng {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 20px;
    color: #000;
}
h2.hdng {
    font-size: 30px;
    color: #000;
    margin: 0 0 15px;
    font-weight: 600;
}
h5.hdng-h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 15px;
    color: #000;
}
p.hdng-16 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
p.hdng-18 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
.custom-btn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
    transition: all 0.3s ease;
}
.custom-btn:hover {
    color: rgb(237 27 36) !important;
}
.custom-btn:hover:after, .custom-btn-2:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
 }
.custom-btn {
    color: #fff !important;
    font-weight: 400;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: rgb(237 27 36);
    padding: 12px 30px;
    text-decoration: none;
    border: 1px solid rgb(237 27 36);
}
.custom-btn span, .custom-btn-2 span {
    position: relative;
    z-index: 9;
}
.custom-btn-2:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(237 27 36);
    transition: all 0.3s ease;
}
.custom-btn-2:hover {
    color: #fff !important;
}
.custom-btn-2 {
    color: rgb(237 27 36) !important;
    font-weight: 400;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: #fff;
    padding: 12px 30px;
    text-decoration: none;
    border: 1px solid rgb(237 27 36);
}
.site-ul {
    padding: 0;
    margin: 0;
}
.site-ul li {
    list-style: none;
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.site-ul li img {
    width: 22px;
    position: relative;
    top: 4px;
}
.logo-carousel {
    padding: 150px 0 90px;
    background: url(../images/top-shape.svg);
    background-position: top center;
    background-size: cover;
}
.logo-carousel li.react-multi-carousel-item {
    padding: 0 5px;
}
.logo-carousel .react-multi-carousel-item img {
    border: 1px solid #ed1b24;
}
.logo-carousel .react-multiple-carousel__arrow {
    background: #ed1b24;
    border-radius: 0;
    min-width: auto;
    min-height: auto;
    max-width: 30px;
    width: 100%;
    height: 30px;
}
.logo-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0;
}
.logo-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0;
}
.logo-carousel .react-multiple-carousel__arrow:before {
    font-size: 16px;
}
.accordion-item button.accordion-button {
    outline: 0;
    box-shadow: 0px 0px 0px 0px #fff;
}
.accordion-item .accordion-button.collapsed {
    padding: 20px;
    border-radius: 0;
    background: #fff;
    color: #000;
}
.accordion-item {
    margin-bottom: 15px;
    border-radius: 0 !important;
    border: 0px;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
}
.accordion-item .accordion-button {
    padding: 20px;
    background: #ed1b24;
    color: #ffff;
    font-size: 20px;
    border-radius: 0 !important;
    font-weight: 600;
}
.accordion-item .accordion-button.collapsed:after {
    filter: brightness(0) invert(0);
}
.accordion-item .accordion-button:after {
    filter: brightness(0) invert(1);
}
.accordion-body tr th {
    font-size: 18px;
    color: #000;
}
.accordion-body tr td {
    padding-bottom: 5px;
    font-size: 16px;
    color: #6a6a6a;
}
.accordion-body p {
    padding-bottom: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
.accordion-body {
    padding: 0;
}
.accordion-body tr th {
    border: 1px solid #eaeaea;
    padding: 10px 20px;
}
.accordion-body tr td {
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    padding: 10px 20px;
}
.accordion-body tr th:last-child {
    border-right: 0;
}
.accordion-body tr th:first-child, .accordion-body tr td:first-child {
    border-left: 0;
}
 /*-- Website Css --*/

/*-- Header Css --*/
.site-header {
    background: #000;
}
.site-header .navbar-collapse > ul.navbar-nav > li.nav-item > a.nav-link {
    min-height: 90px;
    display: flex;
    align-items: center;
}
.site-header .dropdown:hover > .dropdown-menu, .site-header .dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}
.site-header .navbar-collapse {
    justify-content: flex-end;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a {
    padding: 0;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    position: relative;
}
.site-header .navbar-collapse .navbar-nav li.nav-item {
    padding: 0 20px;
}
.site-header .navbar a.navbar-brand {
    padding: 0;
    margin: 0;
}
.site-header .navbar a.navbar-brand img {
    width: 250px;
}
.site-header .navbar {
    padding: 0;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu {
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: 0px 0px 5px 0px #0000003b;
    left: 20px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    width: auto;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li {
    padding: 10px 15px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item.dropend ul.dropdown-menu {
    left: 180px;
    top: 0;
}
.site-header .navbar-collapse .navbar-nav li.nav-item.dropend {
    padding: 0;
    position: relative;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a:hover:before {
    transform: scaleX(1);
    transform-origin: left center;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a:before {
    content: '';
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    border-top-width: 0.143em;
    border-top-style: solid;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border-color: #ed1b24;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a:before {
    border-color: #000;
    border-top-width: 1px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a:hover {
    background: transparent;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li:hover {
    background: rgb(0 0 0 / 5%);
}
.site-header .navbar-collapse .navbar-nav li.nav-item .nav-link.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.business-header {
    padding: 20px 0;
}
.business-header nav.navbar ul.navbar-nav {
    gap: 20px;
}
.nav-link.custom-btn {
    padding: 10px 25px !important;
}
.sticky {
    position: fixed;
    width: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
    background: rgb(0 0 0 / 80%);
}  

/*-- Header Css --*/



/*-- Footer Css --*/
.site-footer {
    background: #333;
}
.site-footer .footer-content img {
    width: 100%;
}
.site-footer .footer-content p {
    font-size: 16px;
    margin-bottom: 0;
    color: rgb(255 255 255 / 70%);
}
.site-footer .footer-content a.footer-logo {
    display: block;
    margin-bottom: 20px;
}
.site-footer .footer-links h4:after {
    content: '';
    background: #ed1b24;
    height: 1px;
    width: 50px;
    position: absolute;
    left: 0;
    bottom: -7px;
}
.site-footer .footer-links h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
    position: relative;
}
.site-footer ul {
    padding-left: 0;
}
.site-footer ul li {
    list-style: none;
    padding-bottom: 3px;
}
.site-footer .bottom-bar {
    border-top: 1px solid rgb(255 255 255 / 20%);
    text-align: center;
    padding: 15px 0;
}
.site-footer .bottom-bar p {
    margin: 0;
    font-size: 13px;
    color: rgb(255 255 255 / 70%);
}
.site-footer .bottom-bar p a {
    color: #ed1b24;
    font-weight: 600;
}
.business-footer {
    padding: 40px 0;
}
.site-footer ul li a {
    color: rgb(255 255 255 / 70%);
    text-decoration: none;
    font-size: 16px;
}
.site-footer ul li a:hover {
    color: #ed1b24;
}
.parallel-websites ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
.site-footer ul li b {
    color: #fff;
}
.parallel-websites li img {
    width: 30px;
}
.parallel-websites li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
}
.social ul {
    display: flex;
    align-items: center;
    gap: 10px;
}
.social ul li img {
    width: 36px;
}
.business-footer .row {
    align-items: center;
}
.business-footer .footer-address ul, .business-footer .footer-social ul {
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.business-footer .footer-address ul li img {
    width: 30px;
}
.business-footer .footer-address ul li {
    display: flex;
    align-items: center;
    gap: 6px;
}
.business-footer .footer-address ul {
    justify-content: space-evenly;
}
/*-- Footer Css --*/





/*-- HomePage Css --*/
.banner {
    background: url(../images/home-banner.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    align-items: center;
    padding: 100px 0 150px;
}
.banner-content h4 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #FFFFFF;
    font-weight: 600;
    margin: 0;
}
.banner-content h1 {
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 10px 0 15px;
}
.banner-content p {
    font-size: 20px;
    color: #FFFFFF;
    margin: 0;
}
.banner-content a.custom-btn {
    margin-top: 35px;
}
.banner-image {
    position: relative;
    text-align: center;
}
.banner-image img {
    width: 80%;
    z-index: 8;
    position: relative;
}
.banner-image img.abxo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
}
.platform-block .banner-image img.abxo {
    height: 360px;
    width: auto;
}
.platform-block .custom-btn-2 {
    margin-left: 20px;
}
.banner .row {
    align-items: center;
}
.about-block {
    padding: 80px 0;
}
.about-block-content .custom-btn {
    margin-top: 15px;
}
.about-block .row {
    align-items: center;
}
.home-points {
    padding: 60px 0;
    background: #111;
}
.home-points .row {
    align-items: center;
}
.home-points .points-content {
    padding-left: 40px;
}
.home-points .site-ul li {
    color: #fff;
}
.consulting-block {
    padding: 60px 0;
    background: #F8F9FC;
}
.consulting-hdngs {
    text-align: center;
    max-width: 750px;
    margin: 0 auto 30px;
}
.consulting-block .row {
    align-items: center;
}
form.from-bottom {
    margin-bottom: 30px;
}
.consulting-block .site-ul li span.image-circle {
    width: 100%;
    height: 55px;
    border: 1px solid #ed1b24;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 55px;
    padding: 12px;
    border-radius: 50%;
}
.consulting-block .site-ul li span.image-circle img {
    top: 0;
    width: 100%;
}
.consulting-block .site-ul li {
    align-items: center;
    margin-bottom: 20px;
    color: #6a6a6a;
}
.consulting-block .site-ul {
    margin-top: 30px;
}
.platform-block .row {
    align-items: center;
    padding: 40px 0;
    position: relative;
}
.platform-block {
    padding: 30px 0;
}
.platform-txt {
    max-width: 500px;
}
.platform-2 .platform-txt {
    margin: 0 0 0 auto;
}
.platform-txt a.custom-btn {
    margin-top: 15px;
}
.vector {
    position: absolute;
    width: 150px;
    bottom: -100px;
    left: 150px;
    right: 0;
    margin: 0 auto;
}
.vector.dg {
    left: -60px;
}
.diagno-block {
    padding: 100px 0;
    background: #111;
    position: relative;
    overflow: hidden;
}
.diagno-block-content h4, .diagno-block-content h2, .diagno-block-content p {
    color: #fff;
}
.diagno-block-content .custom-btn {
    margin-top: 20px;
}
.diagno-block .row {
    align-items: center;
}
.coaches-block {
    padding: 60px 0;
}
.coaches-box img {
    border-radius: 50%;
    max-width: 200px;
    margin: 0 auto 20px;
    display: block;
}
.coaches-box {
    text-align: center;
    padding: 0 40px;
}
.coaches-box h4.box-hdn {
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 26px;
}
.book-block {
    padding: 60px 0;
    background: #ed1b24;
}
.book-block h2.sec-hdng {
    color: #fff;
}
.curve-content {
    position: relative;
}
.curve-content:after {
    content: '';
    background: url(../images/bg-curve.svg) no-repeat;
    background-size: contain;
    width: 600px;
    height: 83px;
    top: -81px;
    position: absolute;
}
.curve-text {
    display: inline-block;
    max-width: 250px;
    text-align: center;
    position: relative;
    left: 170px;
    top: -40px;
}
.logo-carousel h2.sec-hdng {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}
.logo-carousel h2.sec-hdng img {
    width: 120px;
}
.banner-content h3 {
    margin: 15px 0;
    font-size: 30px;
    color: #ed1b24;
    font-weight: 600;
}
.banner-content h5 {
    font-size: 24px;
    color: #fff;
    margin: 0;
}
.book-block p.hdng-16 {
    color: #fff;
}
.business-header nav.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.testimonial-txt {
    background: #ffffff;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px 0px hsl(0deg 0% 0% / 5%);
    margin: 0 10px;
    min-height: 250px;
}
.testimonial-txt h5, .testimonial-txt p {
    color: #000;
}
.testimonial-txt .hdng-16 {
    color: #ed1b24;
    font-weight: 600;
    text-transform: uppercase;
}
/*-- HomePage Css --*/



/*-- Contact Form Css --*/ 

.form-block {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.form-block .form-feild {
    width: 100%;
}
.form-block .form-feild input, .form-block .form-feild select {
    width: 100%;
    height: 60px;
    border-radius: 0;
    border: 1px solid #dddd;
    padding: 0 15px;
    font-size: 16px;
    color: #000;
    outline: 0;
}
.form-block .form-feild input::placeholder {
    color: #000;
}
form.from-bottom .form-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.custom-btn.black:hover {
    border-color: #fff;
    color: #333;
}
.custom-btn.black {
    border: 0px;
    background: #333333;
    padding: 0;
    height: 55px;
    width: 150px;
    font-size: 18px;
}
p.error-msg {
    position: absolute;
    bottom: -45px;
    margin: 0;
    background: #fff;
    padding: 5px 50px;
    color: #000;
}
/*-- Contact Form Css --*/ 




/*-- Implementation Partners Css --*/ 

.id-block {
    padding: 50px 0;
}
.id-block .text-block-impli .text-block-top {
    padding: 15px;
    background: #111;
    text-align: center;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.id-block .text-block-impli .text-block-top h5 {
    color: #fff;
    margin: 0;
}
.id-block .text-block-impli .text-block-bottom {
    padding: 15px;
    background: #111;
    text-align: center;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.id-block .text-block-impli .text-block-bottom p {
    color: #fff;
    margin: 0;
}
.id-block .text-block-impli .text-block-middle {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 30px;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-image img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-image .logo-op img {
    width: auto;
    height: auto;
}
.id-block .text-block-impli {
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
    margin-bottom: 25px;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links img {
    height: 60px;
    width: auto;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links ul {
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links ul li {
    list-style: none;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links ul li img {
    width: 25px;
    height: auto;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links ul li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-links ul li a:hover {
    color: #ed1b24;
}
.id-block .text-block-impli .text-block-middle .text-block-middle-image .logo-op img {
    border-radius: 0;
    height: 60px;
}
.id-block .text-block-impli .text-block-middle-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.id-block .text-block-impli .text-block-middle-links .person-about {
    margin-top: 20px;
}
.id-block .text-block-impli .text-block-middle-links .person-about h5 {
    font-weight: 600;
    font-size: 24px;
}
.id-block .text-block-impli .text-block-middle-links .person-about p a {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}
.id-block .text-block-impli .text-block-middle-links .person-about p {
    margin: 0 0 5px;
}
.id-block .text-block-impli .text-block-middle-links .person-about p a:hover {
    color: #ed1b24;
}

/*-- Implementation Partners Css --*/ 





/*-- Blog  Css --*/ 

.blog-block {
    padding: 50px 0;
}
.blog-box {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 15px 15px 0;
    margin-bottom: 25px;
}
.blog-box img {
    height: 250px;
    object-fit: cover;
}
.blog-box h2 a {
    color: #000;
    text-decoration: none;
}
.blog-box h2 {
    font-size: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-box h2 a:hover {
    color: #ed1b24;
}
.blog-box p.date-txt {
    margin: 0 0 10px;
    font-size: 14px;
    color: #ed1b24;
    text-transform: uppercase;
    font-weight: 600;
}
.blog-box p.hdng-16 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.blog-box .blog-cnt {
    padding: 20px 0;
}
.blog-box a.read-btn {
    text-decoration: none;
    color: #ed1b24;
    font-weight: 600;
}

/*-- Blog  Css --*/ 





/*-- Blog Detail Css --*/ 
.blog-detail-cnt h1 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
}
.blog-txt {
    padding-top: 20px;
    font-size: 16px;
    color: #6a6a6a;
}
.blog-detail-cnt img.post-img {
    height: 450px;
    object-fit: cover;
}
.blog-txt .sign-blog {
    background: #e9e9e9;
    border-left: 5px solid rgb(237 27 36);
    margin: 25px 0;
    padding: 15px 20px;
}
.blog-txt .sign-blog p {
    font-size: 18px;
    font-style: italic;
    margin: 0;
    color: #000;
}
.blog-txt .sign-blog p a {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
}
.blog-txt h4 {
    margin: 30px 0 10px;
    font-size: 24px;
    font-weight: 600;
    color: #000;
}
.blog-share {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid rgb(0 0 0 / 10%);
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    margin: 40px 0;
}
.blog-share h5.hdng-h5 {
    margin: 0;
    margin-right: 30px;
}
.blog-share button {
    border: 0;
    outline: 0;
    padding: 0;
    background: transparent;
}
.blog-txt a {
    font-weight: 600;
    color: #ed1b24;
    text-decoration: none;
}
.blog-share button img {
    width: 30px;
}
.blog-detail-cnt {
    padding: 50px 0;
}
.sidebar {
    margin-top: 50px;
}
.sidebar .blog-sidebar ul {
    padding: 0;
    margin: 0;
}
.sidebar .blog-sidebar ul li {
    list-style: none;
    padding-bottom: 15px;
}
.sidebar .blog-sidebar ul li a {
    color: #000;
    text-decoration: none;
}
.sidebar .blog-sidebar ul li span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #ed1b24;
}
.sidebar .blog-sidebar ul li a:hover {
    color: #ed1b24;
}
.sidebar .blog-sidebar {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 20px;
    margin-bottom: 25px;
}
.form-block.post-comment .form-feild textarea {
    width: 100%;
    height: 120px;
    border-radius: 0;
    border: 1px solid #dddd;
    padding: 15px;
    font-size: 16px;
    color: #000;
    outline: 0;
    margin-top: 25px;
}
form.from-bottom .post-comment  .form-button {
    justify-content: flex-start;
}
.blog-comment .from-bottom {
    margin-bottom: 0;
    margin-top: 30px;
}
.form-block.post-comment {
    position: relative;
}
.form-block.post-comment p.error-msg {
    bottom: -40px;
    background: #ed1b24;
    color: #fff;
}
.form-block.post-comment .form-feild textarea::placeholder {
    color: #000;
}
/*-- Blog Detail Css --*/ 


/*-- Breadcrumb Css --*/ 
.breadcrumb {
    background: #ed1b24;
    padding: 10px 0;
}
.breadcrumb a, .breadcrumb {
    color: #fff;
    text-transform: capitalize;
}
.breadcrumb a {
    font-weight: 700;
    text-decoration: none;
}

/*-- Breadcrumb Css --*/ 


/*-- Video Popup Css --*/ 

.lightbox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}  
.lightbox-content {
    background-color: #fff;
    padding: 20px;
}
.lightbox-content button.close-popup {
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 50%;
    font-size: 24px;
    background: #ed1b24;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: -25px;
    right: -25px;
}
/*-- Video Popup Css --*/ 





/*-- Popup Css --*/ 

.ReactModal__Overlay {
    background: rgb(0 0 0 / 70%) !important;
    z-index: 99999;
}
.ReactModal__Overlay .ReactModal__Content {
    width: 800px;
    border-radius: 0 !important;
    margin: 0 auto;
    height: auto;
    inset: unset !important;
    left: 0 !important;
    right: 0 !important;
    top: 150px !important;
    overflow: visible !important;
}
.ReactModal__Overlay .ReactModal__Content button.close {
    font-size: 0;
    border: 0;
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    outline: 0;
    background: #ed1b24;
    height: 40px;
    border-radius: 50%;
}
.ReactModal__Overlay .ReactModal__Content button.close:after {
    content: '✕';
    color: #fff !important;
    font-size: 22px;
}
.popup-gd {
    cursor: pointer;
}

/*-- Popup Css --*/ 




.id-block .text-block-impli .text-block-top h5.hdng-h5 {
    font-size: 18px;
}